import React from 'react'
//import stack from "../../images/Clone/technologies-stack.svg"



const TechStack = () => {


  return (
    <section className="stack">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mx-auto text-center">
            
            <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Technologies Used</span> in </span>
              Our Foundation Clone Script</h2>
            <p className="pharagraph head">We use updated and cutting-edge technology tools for developing the foundation clone exchange website and mobile application which will be compatible with all major platforms like Android, IOS, and the web.
            </p>
            <div className="quick text-center">
              <img  width="1110px" height="auto" src="https://coinsclone.mo.cloudinary.net/images/binance/technologies-stack.svg" alt="Technologies Stack" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TechStack