import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of </span>
            Our Foundation Clone Script </h2>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures for Foundation clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="406px" height="388px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/requirment-and-planning.png" alt="Development approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3 >Requirement Gathering</h3>
            <p className="pharagraph" >This involves a deep analysis of your project – what you want to construct and other unique requirements. We will be able to tell you during the analysis phase how much it is going to cost you (aside from the script) and how long it is going to take to construct the NFT auctioning marketplace using the Foundation clone script.
            </p>
            <br />
            <h3 >Planning </h3>
            <p className="pharagraph" >Visual demo consists of wireframing and prototyping. This will give you a real sense of how your app is going to look and behave once it has been designed, developed and deployed on your server using the Foundation clone script as its backbone.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h3 >Designing</h3>
            <p className="pharagraph" >Based on the analysis and the approval of the prototype, we will design and develop your NFT platform using our Foundation clone script. This includes coding and UI/UX design as per your preferences and branding needs.</p>
            <br />
            <h3 >Development</h3>
            <p  className="pharagraph">After developing, designing and testing, we will deploy the platform on the web. In case you have an app, we will also deploy the foundation clone script powered mobile app on Apple and Google Play Stores.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-right order1">
            <img width="426px" height="375px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/design-development.png" alt="Designing" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order2" >
            <img width="295px" height="279px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/testing-and-deployment.png" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order1">
            <h3 >Testing</h3>
            <p  className="pharagraph">This is just to make sure that everything works the way it should and there are no kinks.
            </p>
            <br />
            <h3 >Deployment</h3>
            <p  className="pharagraph mb-0">After designing and developing, the platform will be subjected to rigorous testing and when all the kinks have been ironed out, we will deploy the platform on your web server, and it will be ready to use.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach